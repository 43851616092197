<template>
  <div class="card-footer d-flex justify-content-between" v-if="total_rows > 0">
    <small>Showing {{ showingFrom }} to {{ showingUntil }} of {{ total_rows }} entries</small>
    <div class="inline-table">
      <b-pagination
        class="table-cell"
        @change="pageChangeHandler"
        :per-page="per_page"
        :limit="5"
        :total-rows="total_rows"
        v-model="page"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: 'Pagination',
  props: {
    total_rows: {
      type: Number,
      default: 0,
    },
    page: {
      type: [Number, String],
      default: 0,
    },
    per_page: {
      type: Number,
      default: 0,
    },
    list: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  computed: {
    showingFrom() {
      return this.total_rows ? ((this.page - 1) * this.per_page) + 1 : 0;
    },
    showingUntil() {
      if (this.total_rows) {
        if (this.list.length < this.per_page) {
          return this.total_rows;
        }
        return this.page * this.per_page;
      }
      return 0;
    },
  },
  methods: {
    pageChangeHandler(page) {
      this.$emit('pageChange', page);
    },
  },
};
</script>